.hide-on-mobile {
  display: inherit;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.hide-on-pc {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.show-on-pc {
  @media screen and (max-width: 1200px) {
    display: none !important;
  }
}

.show-on-mobile {
  display: none !important;
  @media screen and (max-width: 767px) {
    display: inherit !important;
  }
}

.show-on-mobile-filter {
  display: none !important;
  @media screen and (max-width: 960px) {
    display: inherit !important;
  }
}

.hide-on-mobile-filter {
  display: inherit;
  @media screen and (max-width: 960px) {
    display: none !important;
  }
}

.invisible-on-pc {
  visibility: hidden;
  @media screen and (max-width: 767px) {
    visibility: visible;
  }
}
